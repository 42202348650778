








































































import { Component, Vue } from 'vue-property-decorator';
import ErrorText from '@/views/user/componets/ErrorText.vue';
import '@/assets/css/login/main.css';
import '@/assets/css/login/util.css';

@Component({
  components: { ErrorText },
})
export default class Login extends Vue {
  //$refs!: { [key: string]: any }
  pending: boolean;
  userEmail: string | '';
  userPassword: string | null;

  isRegEmail: boolean;
  $refs!: Vue['$refs'] & {
    userEmail: HTMLElement;
    userPassword: HTMLElement;
  };

  constructor() {
    super();
    this.pending = false;
    this.isRegEmail = true;
    this.userEmail = '';
    this.userPassword = null;
  }

  created() {
    const isLogin = this.$store.getters.isLogin;
    if (isLogin) this.$router.push('/project').catch((err) => {});
  }

  async emailCheck() {
    //공백제거
    if (this.userEmail.trim()) {
      const sendData = {
        userEmail: this.userEmail,
      };
      const emailRegCheck =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*[.][a-zA-Z]{2,3}$/i.test(
          this.userEmail
        );
      // nullCheck , 정규 표현석 reg test  처리
      const { data } = await Vue.axios({
        url: '/user/emailCheck',
        method: 'POST',
        data: sendData,
      });

      this.isRegEmail = !(!emailRegCheck || !data.isRegEmail);
    }
  }

  async login() {
    if (this.userEmail === null || this.userEmail.trim() === '') {
      return this.$toast.open({
        message: '이메일을 입력하여 주세요.',
        type: 'error',
        duration: 3000,
      });
    }

    if (this.userPassword === null || this.userPassword.trim() === '') {
      return this.$toast.open({
        message: '비밀번호를 입력하여 주세요.',
        type: 'error',
        duration: 3000,
      });
    }

    const sendData = {
      userEmail: this.userEmail,
      userPassword: this.userPassword,
    };

    await this.$store.dispatch('login', sendData);
  }

  mounted() {
    console.log('LOGIN PAGE');
  }
}
